import { classnames } from '@tailwindcss-classnames';

export const container = classnames('pt-16', 'pb-2', 'lg:pt-24');

export const image = classnames('rounded-md');

export const articleList = classnames('grid', 'md:grid-cols-2', 'gap-8');

export const headingWrapper = classnames(
  'pb-6',
  'border-b',
  'mb-12',
  'flex',
  'flex-col',
  'md:flex-row',
  'md:justify-between',
  'md:items-end'
);

export const featuredArticles = classnames(
  'grid',
  'lg:grid-cols-2',
  'gap-8',
  'pb-12',
  'border-b',
  'mb-12'
);

export const heading = classnames(
  'text-3xl',
  'tracking-tight',
  'font-extrabold',
  'text-nzxt-light-grey-800',
  'sm:text-4xl',
  'mb-4',
  'md:mb-0'
);

export const itemGridHero = classnames('grid', 'md:grid-cols-1', 'gap-4');

export const itemGrid = classnames('grid', 'sm:grid-cols-2', 'gap-4');

export const itemContent = classnames('pt-1', 'block');

export const itemDate = classnames(
  'text-xs',
  'xl:text-sm',
  'text-nzxt-light-grey-600',
  'pt-1'
);
export const itemTitle = classnames(
  'text-lg',
  'font-bold',
  'leading-snug',
  'tracking-tighter',
  'text-nzxt-light-grey-800'
);

export const itemTitleHero = classnames(
  'text-3xl',
  'font-bold',
  'leading-9',
  'tracking-tighter',
  'text-nzxt-light-grey-800'
);

export const itemExcerpt = classnames(
  'pt-3',
  'text-sm',
  'font-thin',
  'max-w-full',
  'line-clamp-2'
);

export const itemCategory = classnames(
  'uppercase',
  'text-xs',
  'text-nzxt-volt-400',
  'font-semibold'
);
