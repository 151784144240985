import { FC, SelectHTMLAttributes, memo, useMemo, forwardRef } from 'react';
import cn from 'clsx';
import slugify from '@utils/slugify';
import * as styles from './styles';

type Classes = {
  optionsClass?: string;
  labelClass?: string;
  wrapperClass?: string;
  innerLabelClass?: string;
};

type OptionsProps = {
  label: string | number;
  value: string | number;
};

export interface ForwardedSelectProps
  extends SelectHTMLAttributes<HTMLSelectElement> {
  classes?: Classes;
  labelFor: string;
  selectLabel: string;
  options: OptionsProps[];
  selectValue?: string | number;
  srOnlyLabel?: boolean;
  disabled?: boolean;
  inlineLabel?: string;
  isInverted?: boolean;
  isSmol?: boolean;
}

const Select: FC<ForwardedSelectProps> = forwardRef<
  HTMLSelectElement,
  ForwardedSelectProps
>(
  (
    {
      classes,
      labelFor,
      selectLabel,
      options,
      selectValue,
      srOnlyLabel,
      disabled,
      inlineLabel,
      isInverted = false,
      isSmol = false,
      ...rest
    },
    ref
  ) => {
    const rootClassName = cn(
      styles.nativeSelect(
        srOnlyLabel,
        disabled,
        !!inlineLabel,
        isInverted,
        isSmol
      ),
      {},
      classes?.optionsClass
    );
    const labelClassName = useMemo(
      () => cn(styles.selectLabelClass(isInverted), {}, classes?.labelClass),
      [classes]
    );
    const wrapperClassName = useMemo(
      () => cn(styles.wrapper(isSmol), {}, classes?.wrapperClass),
      [classes]
    );
    const innerLabelClassName = useMemo(
      () =>
        cn(styles.innerLabelClass(srOnlyLabel), {}, classes?.innerLabelClass),
      [classes]
    );

    return (
      <label htmlFor={labelFor} className={labelClassName}>
        <span className={innerLabelClassName}>{selectLabel}</span>
        <div className={wrapperClassName}>
          {inlineLabel && (
            <span className={styles.inlineLabel(isInverted)}>
              {inlineLabel}
            </span>
          )}
          <select
            id={slugify(labelFor)}
            name={labelFor}
            className={rootClassName}
            value={selectValue}
            disabled={disabled}
            ref={ref}
            {...rest}
          >
            {options.map((c, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <option key={`${c.value}-${i}s`} value={c.value}>
                {c.label}
              </option>
            ))}
          </select>
        </div>
      </label>
    );
  }
);

export default memo(Select);
