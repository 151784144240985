/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useMemo, memo } from 'react';
import { useRouter } from 'next/router';
import cx from 'clsx';
import Link from 'next/link';
import type { NewsListItem } from '@framework/api/types';
import { ROUTE_NEWS } from '@constants';
import Image from '@components/Image';
import * as styles from './styles';

type Props = {
  isHero?: boolean;
};

const NewsItem: FC<NewsListItem & Props> = ({
  slug,
  title,
  excerpt,
  date,
  newsCategory,
  heroCardImage,
  image,
  defaultHeroCardImage,
  defaultImage,
  isHero,
}) => {
  const { locale } = useRouter();

  const formattedDate = new Date(date).toLocaleDateString(locale, {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  });

  const renderCategory = Array.isArray(newsCategory) ? newsCategory[0] : null;

  const standardImage = image
    ? image.responsiveImage
    : defaultImage.responsiveImage;

  const heroImage = heroCardImage
    ? heroCardImage.responsiveImage
    : defaultHeroCardImage.responsiveImage;

  const composeditemTitleHero = useMemo(
    () => cx(isHero ? styles.itemTitleHero : styles.itemTitle),
    [isHero]
  );

  const composeditemGrid = useMemo(
    () => cx(isHero ? styles.itemGridHero : styles.itemGrid),
    [isHero]
  );

  return (
    <div
      data-test-id={`news-item-article-${slug}`}
      className={composeditemGrid}
    >
      <Link href={`/${ROUTE_NEWS}/${slug}`} data-test-id="news-item-url">
        <Image
          className={styles.image}
          data={isHero ? heroImage : standardImage}
          width={isHero ? heroImage.width : standardImage.width}
          height={isHero ? heroImage.height : standardImage.height}
        />
      </Link>

      <div>
        {renderCategory && (
          <Link
            href={`/${ROUTE_NEWS}/category/${renderCategory.slug}/page/1`}
            data-test-id="news-item-category"
            className={styles.itemCategory}
          >
            {renderCategory.category}
          </Link>
        )}
        <Link href={`/${ROUTE_NEWS}/${slug}`} className={styles.itemContent}>
          <p data-test-id="news-item-title" className={composeditemTitleHero}>
            {title}
          </p>
          <p data-test-id="news-item-date" className={styles.itemDate}>
            <time dateTime={date}>{formattedDate}</time>
          </p>
          <p data-test-id="news-item-excerpt" className={styles.itemExcerpt}>
            {excerpt}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default memo(NewsItem);
