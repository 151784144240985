import { classnames } from '@tailwindcss-classnames';

const isSrOnly = classnames('mt-2.5');
const isDisabled = classnames('cursor-not-allowed');
const isInlineLabel = classnames('rounded-none', 'rounded-r-md');
const defaultBorder = classnames('rounded-md');
const invertedStyles = classnames(
  'bg-nzxt-light-grey-800',
  '!text-white',
  '!bg-blend-luminosity',
  'border-nzxt-dark-grey-400',
  'focus:outline-none',
  'focus:ring-nzxt-volt-200',
  'focus:border-nzxt-volt-200'
);
const defaultSelectBorder = classnames(
  'border-gray-300',
  'focus:outline-none',
  'focus:ring-nzxt-volt-400',
  'focus:border-nzxt-volt-400',
  'dark:bg-nzxt-light-grey-800',
  'dark:border-nzxt-dark-grey-300'
);

const reg = classnames('sm:text-sm');
const smol = classnames('text-xs');

export const nativeSelect = (
  srOnly: boolean,
  disabled: boolean,
  inlineLabel: boolean,
  isInverted: boolean,
  isSmol: boolean
): string =>
  classnames(
    {
      [isSrOnly]: !srOnly,
      [isDisabled]: disabled,
      [isInlineLabel]: inlineLabel,
      [defaultBorder]: !inlineLabel,
      [invertedStyles]: isInverted,
      [defaultSelectBorder]: !isInverted,
      [smol]: isSmol,
      [reg]: !isSmol,
    },
    'block',
    'w-full',
    'pl-3',
    'pr-10',
    'py-2',
    'text-base',
    'dark:text-white',
    'placeholder-gray-400'
  );

const textGray700 = classnames('text-gray-700');
const textWhite = classnames('text-white');

export const selectLabelClass = (isInverted: boolean): string =>
  classnames('block', 'text-sm', 'font-medium', {
    [textWhite]: isInverted,
    [textGray700]: !isInverted,
  });

const defaultInlineLabel = classnames(
  'bg-nzxt-light-grey-50',
  'dark:bg-nzxt-dark-grey-500',
  'text-nzxt-dark-grey-400',
  'dark:text-white'
);
const invertedInlineLabel = classnames(
  'bg-nzxt-light-grey-700',
  'text-nzxt-light-grey-200',
  '!border-nzxt-dark-grey-400'
);

export const inlineLabel = (isInverted: boolean): string =>
  classnames(
    {
      [invertedInlineLabel]: isInverted,
      [defaultInlineLabel]: !isInverted,
    },
    'inline-flex',
    'items-center',
    'px-3',
    'rounded-l-md',
    'border',
    'border-r-0',
    'dark:border-nzxt-dark-grey-400'
  );

export const wrapper = (isSmol): string =>
  classnames('md:max-w-lg', 'flex', 'rounded-md', 'shadow-sm', {
    [smol]: isSmol,
  });

export const innerLabelClass = (srOnlyLabel: boolean): string =>
  classnames('dark:text-white', {
    'sr-only': srOnlyLabel,
  });

export const errorText = classnames('text-red-500', 'mt-2', 'text-xs');
