import { FC, useMemo, memo, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import type { NewsListItem, DatoProductImage } from '@framework/api/types';
import { ROUTE_NEWS } from '@constants';
import ContentWrapper from '@components/ContentWrapper';
import Select from '@components/Select';
import Pagination from '@components/Pagination';
import NewsItem from './NewsItem';
import * as styles from './styles';

type Option = {
  label: string;
  value: string;
};

type Props = {
  categoryOptions: Option[];
  title?: string;
  articles: NewsListItem[];
  totalPages: number;
  currentPage: number;
  routePrefix: string;
  defaultHeroCardImage: DatoProductImage;
  defaultImage: DatoProductImage;
};

const NewsList: FC<Props> = ({
  categoryOptions,
  title,
  articles,
  totalPages,
  currentPage,
  routePrefix,
  defaultHeroCardImage,
  defaultImage,
}) => {
  const { query, push } = useRouter();

  const category = useMemo(
    () => (Array.isArray(query.category) ? query.category[0] : query.category),
    [query]
  );

  const [activeSelect, setActiveSelect] = useState(category);

  const heroItems = useMemo(
    () => (currentPage === 1 ? articles.slice(0, 3) : null),
    [currentPage, articles]
  );

  const remainingItems = useMemo(
    () => (currentPage === 1 ? articles.slice(3) : articles),
    [currentPage, articles]
  );

  useEffect(() => {
    if (typeof activeSelect !== 'undefined' && activeSelect !== category) {
      if (activeSelect === '') {
        push(`/${ROUTE_NEWS}/page/1`);
      } else {
        push(`/${ROUTE_NEWS}/category/${activeSelect}/page/1`);
      }
    }
  }, [activeSelect, category, push]);

  return (
    <ContentWrapper>
      <div data-test-id="news-content" className={styles.container}>
        <div className={styles.headingWrapper}>
          {title && (
            <h1 data-test-id="news-category-title" className={styles.heading}>
              {title}
            </h1>
          )}
          {Array.isArray(categoryOptions) && categoryOptions.length > 1 && (
            <Select
              labelFor="categorySelect"
              data-test-id="news-category-select"
              onChange={e => setActiveSelect(e.currentTarget.value)}
              options={categoryOptions}
              selectLabel="Category"
              selectValue={activeSelect}
            />
          )}
        </div>
        {heroItems && (
          <div className={styles.featuredArticles}>
            <NewsItem
              isHero
              defaultHeroCardImage={defaultHeroCardImage}
              defaultImage={defaultImage}
              {...heroItems[0]}
            />
            <div className="space-y-8">
              {heroItems[1] && (
                <NewsItem
                  defaultHeroCardImage={defaultHeroCardImage}
                  defaultImage={defaultImage}
                  {...heroItems[1]}
                />
              )}
              {heroItems[2] && (
                <NewsItem
                  defaultHeroCardImage={defaultHeroCardImage}
                  defaultImage={defaultImage}
                  {...heroItems[2]}
                />
              )}
            </div>
          </div>
        )}
        <div className={styles.articleList}>
          {remainingItems.map(article => (
            <NewsItem
              key={article.slug}
              defaultHeroCardImage={defaultHeroCardImage}
              defaultImage={defaultImage}
              {...article}
            />
          ))}
        </div>
      </div>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        routePrefix={routePrefix}
      />
    </ContentWrapper>
  );
};

export default memo(NewsList);
